<template>
  <div class="col-sm-12 col-md-8 col-lg-4 form-finish-service mx-auto">
    <KTPortlet :title="codeSession.toString()" headSize="md">
      <template v-slot:body>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="forward-client-group"
            label="Dịch vụ"
            label-for="forwardClient"
            v-if="createActivity"
          >
            <b-form-select
              id="forwardClient"
              :options="allProducts"
              v-model="selectedProductClient"
              @change="onSelectProduct"
              text-field="name"
              value-field="_id"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Giá"
            label-for="input-1"
            v-if="!createActivity"
          >
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="form.price"
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback id="input-1-live-feedback" class="text-left">
            Tài khoản không tồn tại.
          </b-form-invalid-feedback>
          <b-form-group
            id="forward-client-group"
            label="Chuyển tới nhân viên"
            label-for="forward-user"
            v-if="!createActivity"
          >
            <b-form-select
              id="forward-user"
              :options="forwardUsers"
              v-model="selectedUser"
              text-field="name"
              value-field="_id"
              required
            ></b-form-select>
          </b-form-group>
        </b-form>
        <b-button
          v-on:click="onSubmitAction()"
          class="change-btn-right"
          block
          variant="primary"
          >{{ textButton }}</b-button
        >
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
@import "./ForwardClient.scss";
</style>
<script>
import KTPortlet from "@/components/Portlet.vue";
import { mapGetters } from "vuex";
import { number, join } from "@amcharts/amcharts4/core";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";

export default {
  name: "List",
  components: {
    KTPortlet
  },
  data() {
    return {
      selectedForwardClient: "",
      selectedProductClient: "",
      forwardUsers: [
        {
          _id: "",
          name: "Đã hoàn thành"
        }
      ],
      form: {
        price: ""
      },
      query: {},
      codeSession: "",
      done: "Đã hoàn thành",
      selectedUser: "",
      params: {},
      textButton: "Bắt đầu dịch vụ",
      createActivity: true,
      idActivity: "",
      outletIdCurrent: "",
      priceProduct: "",
      codeSession: "",
      idSession: ""
    };
  },
  computed: {
    ...mapGetters(["allProducts"])
  },
  created() {
    this.resetListProduct();
    this.getListProduct();
    this.$store.dispatch("updateListUsers");
    this.getUsers();
    const dataSession = localStorage.getItem("session");
    const parseSession = JSON.parse(dataSession);
    if (parseSession) {
      this.idSession = parseSession.idSession;
      this.codeSession = parseSession.codeSession;
      this.outletIdCurrent = parseSession?.outletId || "";
    }
    const infoProduct = localStorage.getItem("infoProduct");
    const idActivityTemp = localStorage.getItem("idActivity");
    if (infoProduct && idActivityTemp) {
      this.createActivity = false;
    }
    if (idActivityTemp) {
      this.idActivity = JSON.parse(idActivityTemp);
    }
  },
  methods: {
    onSubmit() {
      this.$router.push("/product");
    },
    onSelectProduct(event) {
      this.params.productId = event;
      const itemProduct = this.allProducts.filter(item => item._id === event);

      this.form.price = itemProduct[0].price;
      this.priceProduct = itemProduct[0].price;
      localStorage.setItem("infoProduct", JSON.stringify(itemProduct[0]));
    },

    getListProduct() {
      this.$store.dispatch("product", this.query).then(() => {
        this.selectedProductClient = this.allProducts[0]._id;
        this.form.price = this.allProducts[0].price;
        this.priceProduct = this.allProducts[0].price;
        localStorage.setItem(
          "infoProduct",
          JSON.stringify(this.allProducts[0])
        );
      });
    },

    resetListProduct() {
      this.$store.dispatch("resetListProduct");
    },

    getUsers() {
      this.$store.dispatch("getUsers", { outlet: this.outletId }).then(() => {
        const users = this.$store.state.auth.listUser;
        this.forwardUsers = this.forwardUsers.concat(users);
        this.selectedUser = this.forwardUsers[0]._id;
      });
    },

    onSubmitAction() {
      this.params.sessionId = this.idSession;
      this.params.productId = this.selectedProductClient;
      if (this.createActivity) {
        this.$store.dispatch("createActivity", this.params).then(data => {
          this.createActivity = false;
          this.idActivity = data.data._id;
          this.textButton = "Chuyển tiếp";
          localStorage.setItem("idActivity", JSON.stringify(this.idActivity));
        });
      } else {
        let paramsUpdate = {};

        if (this.selectedUser) {
          paramsUpdate = {
            id: this.idActivity,
            params: {
              price: parseFloat(this.form.price),
              receiverId: this.selectedUser
            }
          };
        } else {
          paramsUpdate = {
            id: this.idActivity,
            params: {
              price: parseFloat(this.form.price)
            }
          };
        }
        this.$store.dispatch("updateActivity", paramsUpdate).then(() => {
          this.createActivity = false;
          localStorage.removeItem("idActivity");
          localStorage.removeItem("infoProduct");
          localStorage.removeItem("session");
          this.$router.push({ path: "/forward-client" });
        });
      }
    }
  }
};
</script>
